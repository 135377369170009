//Never change of remove this one, needed to copy and paste
// const allCaos = [
//     "9999","2297","0824","0823","0822","0827","0826","0880","1285","0487","2630","0945","0427","0316","0405","0727","0838","0509","0549","0683","0924","0623","1165","0301","1612","9997","0833","9996","0696","1536","1345","0051","0526","0233","1287","1496","0748","0496","0679","0218","0021","0182","0730","0433","0050","0041","0043","2535","0848","0759","0254","0027","0418","0392"
// ];

//When a cao is in this list, the message will be shown
//To reset, copy list from AllCaos and paste over here
const caos = [
  "2297", "0824", "0823", "0822", "0827", "0826", "0880", "1285", "0487", "2630", "0945", "0427", "0316", "0405", "0727", "0838", "0509", "0549", "0683", "0924", "0623", "1165", "0301", "1612", "9997", "0833", "9996", "0696", "1536", "1345", "0051", "0526", "0233", "1287", "1496", "0748", "0496", "0679", "0218", "0021", "0182", "0730", "0433", "0050", "0041", "0043", "2535", "0848", "0759", "0254", "0027", "0418", "0392"
];

const caoText = "De salarisberekening wordt uitgevoerd met de Belastingregels van 2025 en de cao gegevens van 2024, de cao's worden zo spoedig mogelijk geactualiseerd."

export const
  getCaoMessage = (caocode, year) => {
    //change year to next year and message will not be shown
    if (Number(year) === 2025) {
      if (caos.indexOf(caocode) > -1) return caoText;
    }
    return undefined;
  };