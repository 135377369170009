export const getCarOptions = (year) => {
  switch (Number(year)) {
    case 2019:
    case 2020:
      return [
        { key: "", value: 'Geen auto van de zaak' },
        { key: 0, value: 'Bijtelling overige auto\'s (25%)' },
        { key: 1, value: 'Bijtelling zeer zuinige auto\'s (14%)' },
        { key: 2, value: 'Bijtelling zuinige auto\'s (20%)' },
        { key: 3, value: 'Bijtelling auto\'s zonder CO2-uitstoot, datum ingang tot 2015 (7/25%)' },
        { key: 4, value: 'Bijtelling auto\'s ouder dan 15 jaar (35%)' },
        { key: 5, value: 'Bijtelling auto\'s met CO2-uitstoot minder dan 51 CO2 (7%)' },
        { key: 6, value: 'Bijtelling auto\'s zonder CO2-uitstoot, vanaf 2015(11/25%), 2016(4/11/25%), 2017(4%), 2019(4/22%), 2020(8/22%)' },
        { key: 7, value: 'Bijtelling auto\'s met CO2-uitstoot 1-50 vanaf 2016 (15%)' },
        { key: 8, value: 'Bijtelling auto\'s met CO2-uitstoot 51-106 vanaf 2016 (21%)' },
        { key: 9, value: 'Bijtelling auto\'s met CO2-uitstoot op naam vanaf 2017 (22%)' },
        { key: "A", value: 'Bijtelling auto\'s op waterstof op naam vanaf 2019 (4%)' }]

    case 2018:
      return [
        { key: "", value: 'Geen' },
        { key: 0, value: 'Bijtelling overige auto\'s (25%)' },
        { key: 1, value: 'Bijtelling zeer zuinige auto\'s (14%)' },
        { key: 2, value: 'Bijtelling zuinige auto\'s (20%)' },
        { key: 3, value: 'Wel auto vd zaak, maar geen bijtelling (0%)' },
        { key: 4, value: 'Bijtelling auto\'s ouder dan 15 jaar (35%)' },
        { key: 5, value: 'Bijtelling auto\'s met CO2-uitstoot minder dan 51 (7%)' },
        { key: 6, value: 'Bijtelling auto\'s zonder CO2-uitstoot op naam vanaf 2014 (4%)' },
        { key: 7, value: 'Bijtelling auto\'s met CO2-uitstoot 1-50 vanaf 2016 (15%)' },
        { key: 8, value: 'Bijtelling auto\'s met CO2-uitstoot 51-106 vanaf 2016 (21%)' },
        { key: 9, value: 'Bijtelling auto\'s met CO2-uitstoot op naam vanaf 2017 (22%)' }]

    case 2021:
      return [
        { key: "", value: 'Geen auto van de zaak' },
        { key: 0, value: 'Bijtelling overige auto\'s (25%)' },
        { key: 1, value: 'Bijtelling zeer zuinige auto\'s (14%)' },
        { key: 2, value: 'Bijtelling zuinige auto\'s (20%)' },
        { key: 3, value: 'Bijtelling auto\'s zonder CO2-uitstoot, datum ingang tot 2015 (7/25%)' },
        { key: 4, value: 'Bijtelling auto\'s ouder dan 15 jaar (35%)' },
        { key: 5, value: 'Bijtelling auto\'s met CO2-uitstoot minder dan 51 CO2 (7%)' },
        { key: 6, value: 'Bijtelling auto\'s zonder CO2-uitstoot, vanaf 2015(11/25%), 2016(4/11/25%), 2017(4%), 2019(4/22%), 2020(8/22%) of 2021(12/22%)' },
        { key: 7, value: 'Bijtelling auto\'s met CO2-uitstoot 1-50 vanaf 2016 (15%)' },
        { key: 8, value: 'Bijtelling auto\'s met CO2-uitstoot 51-106 vanaf 2016 (21%)' },
        { key: 9, value: 'Bijtelling auto\'s met CO2-uitstoot op naam vanaf 2017 (22%)' },
        { key: "A", value: 'Bijtelling auto\'s op waterstof op naam vanaf 2019 (4%), 2020 (8%) of 2021 (12%)' },
        { key: "B", value: 'Bijtelling zonnecelauto\'s op naam vanaf 2021 (12%)' }]

    case 2022:
      return [
        { key: "", value: 'Geen auto van de zaak' },
        { key: 0, value: 'Bijtelling overige auto\'s (25%)' },
        { key: 1, value: 'Bijtelling zeer zuinige auto\'s (14%)' },
        { key: 2, value: 'Bijtelling zuinige auto\'s (20%)' },
        { key: 3, value: 'Bijtelling electrische auto\'s zonder CO2-uitstoot, datum ingang tot 2015 (7/25%)' },
        { key: 4, value: 'Bijtelling auto\'s ouder dan 15 jaar (35%)' },
        { key: 5, value: 'Bijtelling auto\'s met CO2-uitstoot minder dan 51 CO2 (7%)' },
        { key: 6, value: 'Bijtelling electische auto\'s zonder CO2-uitstoot, 2015(11/25%), 2016(15/25%), 2017(4/12/22%), 2018(4%), 2019(4/22%), 2020(8/22%), 2021(12/22%), 2022(16/22%)' },
        { key: 7, value: 'Bijtelling auto\'s met CO2-uitstoot 1-50 vanaf 2016 (15%)' },
        { key: 8, value: 'Bijtelling auto\'s met CO2-uitstoot 51-106 vanaf 2016 (21%)' },
        { key: 9, value: 'Bijtelling auto\'s met CO2-uitstoot op naam vanaf 2017 (22%)' },
        { key: "A", value: 'Bijtelling auto\'s op waterstof op naam vanaf 2019(4%), 2020(8%), 2021(12%) of 2022(16%)' },
        { key: "B", value: 'Bijtelling zonnecelauto\'s op naam vanaf 2021 (12%) of 2022 (16%)' }]

    case 2023:
      return [
        { key: "", value: 'Geen auto van de zaak' },
        { key: 0, value: 'Bijtelling overige auto\'s (25%)' },
        { key: 1, value: 'Bijtelling zeer zuinige auto\'s (14%)' },
        { key: 2, value: 'Bijtelling zuinige auto\'s (20%)' },
        { key: 3, value: 'Bijtelling electrische auto\'s zonder CO2-uitstoot, datum ingang tot 2015 (19/25%)' },
        { key: 4, value: 'Bijtelling auto\'s ouder dan 15 jaar (35%)' },
        { key: 5, value: 'Bijtelling auto\'s met CO2-uitstoot minder dan 51 CO2 (7%)' },
        { key: 6, value: 'Bijtelling electrische auto\'s zonder CO2-uitstoot, vanaf 2015(19/25%), 2017(16/22%), 2018(4/16/22%), 2019(4/22%), 2020(8/22%), 2021(12/22%), 2022(16/22%) of 2023 / 2024(16/22%)' },
        { key: 7, value: 'Bijtelling auto\'s met CO2-uitstoot 1-50, 2016 (25%) of vanaf 2017 (22%)' },
        { key: 8, value: 'Bijtelling auto\'s met CO2-uitstoot 51-106, 2016 (25%) of vanaf 2017 (22%)' },
        { key: 9, value: 'Bijtelling auto\'s niet volledig elektrisch vanaf 2017 (22%)' },
        { key: "A", value: 'Bijtelling auto\'s op waterstof op naam vanaf 2019(4%), 2020(8%), 2021(12%) of 2022(16%)' },
        { key: "B", value: 'Bijtelling zonnecelauto\'s op naam vanaf 2021 (12%) of 2022 (16%)' }]

    case 2024:
    default:
      return [
        { key: "", value: 'Geen auto van de zaak' },
        { key: 1, value: 'Met CO2 uitstoot' },
        { key: 2, value: 'Elektrische auto' },
        { key: 3, value: 'Waterstof auto' },
        { key: 4, value: 'Zonnecel auto' },
        { key: 5, value: 'Auto ouder dan 15 jaar' }
      ]
  }
}