import {
  getComponent,
  PrepayPages,
  inputErrors,
  findComponent
} from '../defaults/componentState'
import { getCaoMessage } from '../defaults/caoRemarks'
import {} from '../actions/inputActions'

import {
  componentsInfoCompleteType,
  writeValueSucessType,
  loadPagesTypeCompleteType,
  yearChangeType,
  yearChangeSucessType,
  yearChangeFailureType,
  calcuateValidationFailureType,
  yearChangeCompleteType
} from '../actions'

const caoLists = []

export const UpdateValue = (pageIndex, id, value, action = null) => {
  return {
    type: action || writeValueSucessType,
    response: {
      pageIndex,
      id,
      value
    }
  }
}

export const YearChange = (value) => {
  const endpoint = `/api/prepay/AvailableCao?year=${value}`

  return {
    api: {
      type: yearChangeType,
      resource: {
        endpoint,
        method: 'GET'
      }
    }
  }
}

export const changePage = (pageIndex = 0) => {
  return {
    type: componentsInfoCompleteType,
    response: {
      pageIndex: pageIndex,
      page: PrepayPages[pageIndex]
    }
  }
}

export const loadPages = () => {
  return {
    type: loadPagesTypeCompleteType,
    response: {
      pages: PrepayPages
    }
  }
}

const yearChangeHandler = (state, fiscalYear, caos) => {
  const { pages } = { ...state }
  const frequency = getComponent(pages[0], '3610_0').value
  const caoBox = findComponent(pages, 'generic', 'cao')
  const remark = getCaoMessage(caoBox.value, fiscalYear)

  const newPages = pages.map((page, pageIndex) => {
    const newPage = page.onYearChange ? page.onYearChange({ self: page, pages, caos }) : page

    const components = newPage.components.map(c =>  {
      const item = c.onYearChange ? c.onYearChange({ item: c, pages, caos, fiscalYear, frequency }) : c
      return { ...item }
    })

    return { ...newPage, components }
  })

  return {
    ...state,
    fiscalYear,
    pages: newPages,
    caos,
    updatedId: 'year',
    updatedValue: fiscalYear,
    validationError: undefined,
    remark: remark
  }
}



const defaultState = {
  fiscalYear: 2025,
  pages: undefined,
  updatedId: undefined,
  updatedValue: undefined,
  validationError: undefined,
  remark: undefined
}

export const reducer = (state = defaultState, action) => {
  const { pages } = { ...state }

  switch (action.type) {
    case writeValueSucessType: {
      const { pageIndex, id, value } = action.response
      const { caos, fiscalYear } = state
      const page = pages[pageIndex]
      const target = page.components.find(i => i.id === id)
      target.value = value
      target.isDirty = true

      //  validation
      page.inError = !page.validate()

      inputErrors(pages, true, true)

      const newPages = pages.map(page => {
        const newPage = page.onChangeNew ? page.onChangeNew({ target, self: page, pages, caos }) : page

        const components = newPage.components.map(c =>  {
          const item = c.onChangeNew ? c.onChangeNew({ target, self: c, pages, fiscalYear }) : c

          return { ...item }
        })

        return { ...newPage, components }
      })
      const caoBox = findComponent(pages, 'generic', 'cao')
      const remark = getCaoMessage(caoBox.value, fiscalYear)

      return {
        ...state,
        pages: newPages,
        updatedId: id,
        updatedValue: value,
        validationError: undefined,
        remark
      }
    }

    case componentsInfoCompleteType:
      return {
        ...state,
        page: action.response.page
      }

    case loadPagesTypeCompleteType:
      return {
        ...state,
        pages: action.response.pages
      }

    case yearChangeFailureType:
      const {
        error
      } = action.response

      return {
        ...state, error
      }

    case yearChangeCompleteType:
      return yearChangeHandler(state, action.response.year, action.response.caos)

    case yearChangeSucessType: {
      caoLists.push(action.response)
      return yearChangeHandler(state, action.response.year, action.response.caos)
    }

    case calcuateValidationFailureType:
      return {
        ...state, pages: action.response.input, validationError: action.response.errors[0]
      }

    default:
      return state
  }
}
